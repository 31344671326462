import {
	registerComponent,
	TComponentInit,
} from '@hrmony/component-library/scripts'

const identifier = 'overlay'

const OverlayComponent: TComponentInit = (element) => {
	const triggers: NodeListOf<HTMLElement> = document.querySelectorAll(
		`[data-dialog-open="${element.id}"]`,
	)
	const closeBtn: HTMLElement | null =
		element.querySelector(`[data-dialog-close]`)

	const open = (event: Event) => {
		event.preventDefault()
		;(element as HTMLDialogElement).showModal()
	}

	const close = () => {
		;(element as HTMLDialogElement).close()
	}

	const closeOnBackdropClick = (event) => {
		const rect = element.getBoundingClientRect()
		const isInDialog =
			rect.top <= event.clientY &&
			event.clientY <= rect.top + rect.height &&
			rect.left <= event.clientX &&
			event.clientX <= rect.left + rect.width

		if (!isInDialog) {
			close()
		}
	}

	const onDialogClick = (event) => {
		closeOnBackdropClick(event)
	}

	return {
		element,
		init: () => {
			element.addEventListener('click', onDialogClick)

			triggers.forEach((trigger) => {
				trigger.addEventListener('click', open)
			})

			closeBtn?.addEventListener('click', close)
		},
		destroy: () => {
			element.removeEventListener('click', onDialogClick)

			triggers.forEach((trigger) => {
				trigger.removeEventListener('click', open)
			})

			closeBtn?.removeEventListener('click', close)
		},
	}
}

registerComponent(identifier, OverlayComponent)

export default {
	identifier,
	OverlayComponent,
}
