import 'vite/modulepreload-polyfill'
import '../styles/main.scss'

import {
	findAndInitAllComponents,
	initSentry,
} from '@hrmony/component-library/scripts'

// Components
import '~components/DateNavigation/DateNavigation.component'
import '~components/Form/Form.component'
import '~components/LoadingOverlay/LoadingOverlay.component'
import '~components/Overlay/Overlay.component'
import '~components/VoucherCode/VoucherCode.component'
import '~pages/sachbezug/SachbezugPartnerdetail/VoucherSelect.component'

// init
const registerServiceWorker = (window: Window): void => {
	const serviceWorkerScope = window.__DEV__
		? '/src/scripts/service-worker/'
		: '/'
	try {
		if (
			'serviceWorker' in window.navigator &&
			window._hrmonyConfig?.serviceWorker?.url
		) {
			window.navigator.serviceWorker
				.register(`/${window._hrmonyConfig.serviceWorker.url}`, {
					scope: serviceWorkerScope,
				})
				.then(() => {})
				.catch((error) => {
					console.error(error)
				})
		}
	} catch (error) {
		console.error(error)
	}
}

registerServiceWorker(window)

initSentry('mba')

findAndInitAllComponents()
