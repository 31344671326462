import {
	registerComponent,
	TComponentInit,
} from '@hrmony/component-library/scripts'

export const identifier = 'date-navigation'

const DateNavigationComponent: TComponentInit = (element) => {
	const select = element.querySelector(
		'[data-date-navigation-select]',
	) as HTMLSelectElement | null

	console.log(identifier)

	function onChange() {
		const value = select?.selectedOptions[0].value ?? window.location.href

		window.location.href = value
	}

	function init() {
		select?.removeAttribute('disabled')

		select?.addEventListener('change', onChange)
	}

	function destroy() {
		select?.setAttribute('disabled', '')

		select?.removeEventListener('change', onChange)
	}

	return {
		element,
		init,
		destroy,
	}
}

registerComponent(identifier, DateNavigationComponent)

export default {
	identifier,
	DateNavigationComponent,
}
