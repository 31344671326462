import {
	registerComponent,
	TComponentInit,
} from '@hrmony/component-library/scripts'
import { emitter } from '@hrmony/component-library/scripts/event-emitter'

let loadingOverlayElement: Element | null = null
let loadingMessageElement: Element | null = null

export const identifier = 'loading-overlay'

export type LoadingOverlayEvents = {
	'loading:start': (message?: string) => void
	'loading:stop': () => void
}

type LoadingOverlayTriggerElement = HTMLElement & {
	__startLoading?: (event: Event) => void
}

const LoadingOverlayComponent: TComponentInit = (element) => {
	const triggers = document.querySelectorAll(
		`[data-show-${identifier}]`,
	) as NodeListOf<LoadingOverlayTriggerElement>
	const init = () => {
		if (loadingOverlayElement !== null) {
			console.warn('Loading overlay already registered.')
			return
		}

		loadingOverlayElement = element
		loadingMessageElement = loadingOverlayElement.querySelector(
			`[data-${identifier}-message]`,
		)
		loadingOverlayElement.removeAttribute('hidden')
	}

	const updateLoadingMessage = (message: string) => {
		if (loadingMessageElement !== null) {
			// preact sets the attribute to true
			// if the attribute has no value
			if (message === 'true') {
				message =
					loadingMessageElement.getAttribute(`data-${identifier}-message`) ?? ''
			}

			loadingMessageElement.textContent = message
		}
	}

	const startLoading = (message = '') => {
		if (loadingOverlayElement === null) {
			console.warn('Loading overlay not registered')
			return
		}

		document.body.setAttribute('inert', '')

		updateLoadingMessage(message)

		loadingOverlayElement.setAttribute(`data-${identifier}`, 'loading')
	}

	const stopLoading = () => {
		document.body.removeAttribute('inert')

		if (loadingOverlayElement === null) {
			console.warn('Loading overlay not registered')
			return
		}

		updateLoadingMessage('')

		loadingOverlayElement.setAttribute(`data-${identifier}`, '')
	}

	emitter.on('loading:start', startLoading)
	emitter.on('loading:stop', stopLoading)

	triggers.forEach((trigger) => {
		const triggerTypeSelect = document.querySelector('select')
		const message = trigger.getAttribute(`data-loading-message`) ?? ''
		trigger.__startLoading = startLoading.bind(null, message)

		if (trigger === triggerTypeSelect) {
			trigger.__startLoading &&
				trigger.addEventListener('change', trigger.__startLoading)
		} else {
			trigger.__startLoading &&
				trigger.addEventListener('click', trigger.__startLoading)
		}
	})

	return {
		element,
		init,
		destroy: () => {
			emitter.off('loading:start', startLoading)
			emitter.off('loading:stop', stopLoading)

			triggers.forEach((trigger) => {
				if (trigger.__startLoading) {
					trigger.removeEventListener('click', trigger.__startLoading)
					trigger.removeEventListener('change', trigger.__startLoading)
				}
			})
		},
	}
}

registerComponent(identifier, LoadingOverlayComponent)

export default {
	identifier,
	LoadingOverlayComponent,
}
